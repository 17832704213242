import {
  BaseValueUnit,
  Unit,
} from "@superfiliate/graphql-sdk/src/lib/__generated__/microsite";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const intFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const discountToString = (input: BaseValueUnit): string => {
  if (!input) return "$0";

  const { unit, value } = input;

  if (unit === Unit.Percentage) {
    return `${value}%`;
  }

  return value % 1 === 0 ? intFormatter.format(value) : formatter.format(value);
};

export default discountToString;
