"use client";

import { FC, useEffect } from "react";

import useAttributionWindow from "@microsite/lib/hooks/useAttributionWindow";
import { PageProps } from "@utils/microsites/types/microsite";

const AttributionWindowScripts: FC<PageProps> = ({
  campaignSuperfiliateMicrosite,
}) => {
  const { setPageAccessCookie, sendPageAccessEvent } = useAttributionWindow();
  const shop = campaignSuperfiliateMicrosite?.shop;

  const shopId = shop?.id;
  const code = campaignSuperfiliateMicrosite?.campaignSuperfiliate?.code;

  useEffect(() => {
    setPageAccessCookie();
    if (shopId && code) sendPageAccessEvent(shopId, code);
  }, [shopId, code]);

  return null;
};

export default AttributionWindowScripts;
