"use client";

import React, { useCallback } from "react";

import { MediaPlayerStates } from "@microsite/components/molecules/MediaPlayer/types";
import MediaPlaylist from "@microsite/components/molecules/MediaPlaylist";
import PhoneFrame from "@microsite/components/molecules/PhoneFrame";
import cn from "@utils/cn";
import { StoriesVariation } from "@utils/microsites/personalization/sectionsVariationsEnums";
import { PartialStory } from "@utils/microsites/types/personalization";

interface Props {
  media: PartialStory;

  isMobile: boolean;
  isActive: boolean;
  isNext: boolean;
  isPrevious: boolean;

  moveBackward?: () => void;
  moveForward?: () => void;
}

const Item: React.FC<Props> = ({
  media,
  isMobile,
  isActive,
  isNext,
  isPrevious,
  moveBackward,
  moveForward,
}) => {
  const onClick = useCallback(() => {
    if (isActive) return;

    if (isNext) return moveForward?.();
    if (!isNext) return moveBackward?.();
  }, [isActive, isNext]);

  return (
    <PhoneFrame
      onClick={onClick}
      className={cn(
        "mx-[min(16px,var(--sf-custom-padding))] laptop:mx-sf-padding",
        {
          "opacity-50": isMobile && !isActive,
          "opacity-90": !isMobile && !isActive,
          "laptop:animate-fade-out": !isActive && !isNext,
          "laptop:mask-gradient-r": !isActive && isNext,
        },
      )}
    >
      {isActive || isNext || isPrevious ? (
        <MediaPlaylist
          playlist={[media]}
          style={StoriesVariation.CAROUSEL}
          state={
            isActive ? MediaPlayerStates.ACTIVE : MediaPlayerStates.PREVIEWING
          }
          onPreviousPlaylist={() => {
            isActive && moveBackward?.();
          }}
          onNextPlaylist={() => {
            isActive && moveForward?.();
          }}
          loopMedia={isActive && isNext}
          loopPlaylist={false}
          swipeable={false}
        />
      ) : (
        false
      )}
    </PhoneFrame>
  );
};

export default Item;
