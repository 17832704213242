import dynamic from "next/dynamic";

import Image from "@customer-ui/components/Image";
import cn from "@utils/cn";

const OpenCartIconButton = dynamic(
  () => import("@microsite/components/organisms/OpenCartIconButton"),
  { ssr: false },
);

interface HeaderProps {
  logoMediaId: string;
  cart?: boolean;
}

const Header: React.FC<HeaderProps> = ({ logoMediaId, cart = false }) => {
  return (
    <>
      <Image
        className={cn("object-contain h-9", { "object-left": cart })}
        src={logoMediaId}
        alt="Header Logo"
        width={160}
        height={36}
        placeholder={false}
      />

      {cart && (
        <div className="scale-90">
          <OpenCartIconButton ring />
        </div>
      )}
    </>
  );
};

export default Header;
