"use client";

import dynamic from "next/dynamic";
import React, { useContext, useEffect } from "react";

import GenericSectionLoader from "@microsite/components/molecules/GenericSectionLoader";
import LazySectionContainer from "@microsite/components/molecules/LazySectionContainer";
import { GetYProductsProvider } from "@microsite/contexts/getYProducts";
import { UpsellProductsProvider } from "@microsite/contexts/upsellProducts";
import useCartState from "@microsite/state/cart";
import useSuperfiliateState from "@microsite/state/superfiliate";
import cn from "@utils/cn";
import { CampaignSuperfiliateMicrositeContext } from "@utils/microsites/contexts";
import findMicrositeApp, {
  Apps,
} from "@utils/microsites/personalization/findMicrositeApp";
import { PersonalizationSectionWithPseudoId } from "@utils/microsites/personalization/orderMicrositeSections";

const Content = dynamic(
  () => import("@microsite/components/organisms/ProductSection/Content"),
  { loading: () => <GenericSectionLoader /> },
);

const CartDrawer = dynamic(
  () => import("@microsite/components/organisms/CartDrawerDefault"),
  { ssr: false },
);

interface Props {
  sectionData: PersonalizationSectionWithPseudoId;
}

const Product: React.FC<Props> = ({ sectionData }) => {
  const { isPreview, personalization } = useContext(
    CampaignSuperfiliateMicrositeContext,
  );

  const code = useSuperfiliateState((state) => state.code);

  const cartEnabled = useCartState((state) => state.isEnabled);
  const enable = useCartState((state) => state.enable);
  const setDiscountCode = useCartState((state) => state.setDiscountCode);

  const injectDiscountCode = () => {
    if (!code) return;

    setDiscountCode(code as string);
  };

  const enableCartApp = () => {
    if (!personalization) return;

    const cartSettings = findMicrositeApp(personalization, Apps.Cart);

    if (cartSettings?.enabled) enable(cartSettings);
  };

  useEffect(injectDiscountCode, [code]);
  useEffect(enableCartApp, [personalization]);

  return (
    <LazySectionContainer>
      <UpsellProductsProvider>
        <GetYProductsProvider>
          <div className={cn({ "pointer-events-none": isPreview })}>
            <Content sectionData={sectionData} />
          </div>

          {cartEnabled && <CartDrawer />}
        </GetYProductsProvider>
      </UpsellProductsProvider>
    </LazySectionContainer>
  );
};

export default Product;
