import React from "react";

import Stars from "@customer-ui/icons/Stars";
import cn from "@utils/cn";

interface Props extends React.PropsWithChildren {
  image?: React.ReactNode;
  title: React.ReactNode;
  reviewsMessage?: React.ReactNode | null;
  className?: string;
}

const MicrositeStoryFooter: React.FC<Props> = ({
  children,
  image,
  title,
  reviewsMessage,
  className,
}) => (
  <div
    className={cn(
      "w-full bg-[#222222]/60 p-2 laptop:p-4 backdrop-blur-xl rounded-[min(32px,var(--sf-custom-radius,20px))]",
      "flex items-center shadow border-2 border-[#00000011] text-left",
      className,
    )}
  >
    {image && (
      <div className={cn("overflow-hidden flex justify-center items-center")}>
        {image}
      </div>
    )}

    <div
      className={cn("flex-1 overflow-hidden pr-2", {
        "pl-1": !image,
        "pl-2": image,
      })}
    >
      {/* In case the Microsite title has been heavily customized with inline styles,
          we need to force its size to properly be readable inside the Portal */}
      <div className="font-body font-bold text-xs xs:text-sm line-clamp-2 force-font-size-inherit">
        {title}
      </div>

      {reviewsMessage && (
        <div className="flex flex-wrap gap-1 items-center text-[8px]">
          <Stars amount={5} className="gap-0.5" />

          <div
            className={cn(
              "font-thin text-white font-display",
              "whitespace-break-spaces text-[8px]",
            )}
          >
            {reviewsMessage}
          </div>
        </div>
      )}
    </div>

    {children}
  </div>
);

export default MicrositeStoryFooter;
