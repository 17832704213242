import generateImageURL from "@utils/media/generateImageURL";
import { CommonMedia } from "@utils/types";
import { useMemo } from "react";

const useBackgroundImageStyle = (media: CommonMedia | null | undefined) => {
  const value = useMemo(() => {
    if (!media)
      return {
        backgroundColor: "rgba(var(--sf-base-swappable), 1)",
      };

    return {
      backgroundImage: [
        "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))",
        `, url(${generateImageURL(media.mediaId, "w=2560")})`,
      ].join(""),
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  }, [media]);

  return value;
};

export default useBackgroundImageStyle;
