import React from "react";
import { StarFill, StarHalf } from "react-bootstrap-icons";

import cn from "@utils/cn";

const Stars: React.FC<
  React.PropsWithChildren<{
    amount?: number;
    color?: string;
    className?: string;
  }>
> = ({ amount = 5, color, className = "" }) => {
  const halves = amount * 2;
  const arrayFulls = new Array(parseInt(`${halves / 2}`)).fill(0);
  const remainingHalf = !!(halves % 2);

  return (
    <div
      className={cn(
        `flex flex-wrap justify-center gap-1`,
        { "text-[#FFB400]": !color },
        color,
        className,
      )}
    >
      {arrayFulls.map((_, index) => (
        <StarFill key={index} />
      ))}
      {remainingHalf && <StarHalf />}
    </div>
  );
};

export default Stars;
