import { PropsWithChildren } from "react";
import { overrideTailwindClasses } from "tailwind-override";

import ThemeWrapper, { ThemeColor } from "@microsite/components/ThemeWrapper";
import cn from "@utils/cn";

interface SectionProps extends PropsWithChildren {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  noPaddingX?: boolean;
  noPaddingY?: boolean;
  paddingY?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
  wrapperClassName?: string;
  bgClass?: string;
  theme?: ThemeColor;
  backgroundImageUrl?: string | null;
}

const Section: React.FC<SectionProps> = ({
  className,
  style,
  wrapperClassName,
  noPaddingX,
  paddingY,
  noPaddingY,
  children,
  fullWidth = false,
  theme = "base",
  backgroundImageUrl,
  ...rest
}) => {
  const fullStyle = style || {};

  if (backgroundImageUrl) {
    fullStyle["backgroundImage"] = `url(${backgroundImageUrl})`;
  }

  return (
    <ThemeWrapper
      theme={theme}
      className={overrideTailwindClasses(
        cn(
          "bg-cover text-base-content",
          { "bg-base": theme !== "base" },
          wrapperClassName,
        ),
      )}
      style={{
        ...fullStyle,
      }}
      {...rest}
    >
      {fullWidth ? (
        <div className={className}>{children}</div>
      ) : (
        <div
          className={overrideTailwindClasses(
            cn(
              "container mx-auto max-w-screen-2xl",
              {
                "px-4 md:px-8 lg:px-28": !noPaddingX,
                [paddingY || "py-16"]: !noPaddingY,
              },
              className,
            ),
          )}
        >
          {children}
        </div>
      )}
    </ThemeWrapper>
  );
};

export default Section;
