"use client";

import React from "react";

import cn from "@utils/cn";

interface Props extends React.PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  desktopOnly?: boolean;
}

const PhoneFrame: React.FC<Props> = ({
  className,
  style,
  children,
  onClick,
  desktopOnly = false,
}) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        "laptop:border-8",
        {
          "laptop:bg-black laptop:border-4 laptop:border-base-content laptop:overflow-hidden laptop:rounded-sf-radius":
            desktopOnly,
          "bg-black border-4 border-base-content overflow-hidden rounded-sf-radius":
            !desktopOnly,
        },
        className,
      )}
      style={style}
    >
      {/*
        It needs to be 2 separate <div>s for the `border` to not mess up the aspect-ratio.
        Also adds a really small `scale` factor, to cover 0.5px gaps that may appear on "retina" screens,
        because of the 16/9 aspect-ratio creating non-integer pixel values.
      */}
      <div
        className={cn("laptop:h-[600px]", {
          "laptop:relative laptop:aspect-story laptop:scale-[1.005] laptop:overflow-hidden":
            desktopOnly,
          "relative aspect-story h-[317px] scale-[1.005] overflow-hidden":
            !desktopOnly,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default PhoneFrame;
